<template>
  <div>
    <LoadingSpinner :isLoading="isLoading"></LoadingSpinner>
    <div v-if="!isLoading">
      <div class="container space-top">
        <div class="row-1-col">
          <div class="row-item">
            <div class="page-header">
              <h1>LEV-6 Rollista</h1>
            </div>
            <div class="row-flex space-top-sm">
              <RFilter configName="veda" v-model="filters.veda.selected" />
              <RFilter configName="portal_roles" v-model="filters.roles.selected" :EndpointParameter="{ roles: ['CENTRAL', 'BUSINESS', 'INVOICEREFERENCE'] }" />
            </div>
          </div>
        </div>

        <div class="row-1-col space-top">
          <h2>Data</h2>
          <TablePaginated :tableData="tableData" :columns="columns" :disableFiltering="false" csvFilename="LEV-6 Export - Rollista" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrossfilterHandler from "@/utils/crossfilter/crossfilterHandler.js";

export default {
  name: "Lev6",
  props: { endpoint: String },
  data() {
    return {
      isLoading: true,
      tableData: [],
      crossfilter: null,
      columns: [
        // {
        //   property: "CompanyId",
        //   displayName: "FörvaltningsId",
        //   show: false,
        // },
        // {
        //   property: "Company",
        //   displayName: "Förvaltning",
        //   show: true,
        // },
        {
          property: "DepartmentId",
          displayName: "VerksamhetsId",
          show: false,
        },
        {
          property: "Department",
          displayName: "Verksamhet",
          show: true,
        },
        {
          property: "Role",
          displayName: "Roll",
          show: false,
        },
        {
          property: "RoleName",
          displayName: "Rollnamn",
          show: true,
        },
        {
          property: "UserName",
          displayName: "Namn",
          show: true,
        },
        {
          property: "UserUID",
          displayName: "AnvändarUID",
          show: true,
        },
        {
          property: "Email",
          displayName: "Epost",
          show: true,
        },
        {
          property: "Phone",
          displayName: "Telefon",
          show: true,
        },
        {
          property: "Mobile",
          displayName: "Mobil",
          show: true,
        },
        {
          property: "InvoiceReference",
          displayName: "Fakturareferens",
          show: false,
        },
        {
          property: "CostCenter",
          displayName: "Kostnadsställe",
          show: false,
        },
      ],
      filters: {
        roles: { selected: [] },
        veda: { selected: [] },
      },
    };
  },
  watch: {
    "filters.veda.selected"() {
      this.crossfilter.filterDimensionArrayContains("VedaDimension", this.filters.veda.selected);
      this.calculateReportComponentsData();
    },
    "filters.roles.selected"() {
      this.crossfilter.filterDimensionArrayContains("RoleDimension", this.filters.roles.selected);
      this.calculateReportComponentsData();
    },
  },
  methods: {
    initCrossfilter() {
      this.crossfilter = new CrossfilterHandler(this.tableData);
      this.crossfilter.createDimensionByProperty("RoleDimension", "Role");
      this.crossfilter.createDimensionByProperty("VedaDimension", "DepartmentId");
      this.calculateReportComponentsData();
    },
    calculateReportComponentsData() {
      this.tableData = this.crossfilter.allFiltered();
    },
  },
  computed: {},
  mounted() {
    this.$http.all([this.$http.get("/api/reporting/delivery/roles", { params: { roles: ["CENTRAL", "BUSINESS", "INVOICEREFERENCE"] } })]).then(
      this.$http.spread(rolesResponse => {
        this.tableData = rolesResponse.data;
        this.initCrossfilter();
        this.calculateReportComponentsData();
        this.isLoading = false;
      }),
    );
  },
};
</script>
